import { RefObject, useCallback, useRef, useState } from 'react';
import { useMegamenu } from '@gemini/brand/el/ui/organisms/use-megamenu';
import { BasicMenuRef } from '@gemini/brand/el/ui/templates/basic-menuref';
import { BasicMenuRefAerin } from '@gemini/brand/el/ui/templates/basic-menuref-aerin';
import { BasicMenuRefRenutriv } from '@gemini/brand/el/ui/templates/basic-menuref-renutriv';
import { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';

interface INavDesktopProps {
  navLinks: TemplateType[];
  classes?: string;
  menuRef: RefObject<HTMLDivElement>;
}

export const NavDesktop = ({ navLinks, classes }: INavDesktopProps) => {
  const { show, hide } = useMegamenu();
  const [activeSafeAreaKey, setActiveSafeAreaKey] = useState('');

  const ULRef = useRef<HTMLUListElement>(null);
  const safeAreasRef: { [key: string]: RefObject<HTMLElement> } = {};
  let activeSafeArea: RefObject<HTMLElement> | null = null;

  const updateSafeTargetArea = useCallback((e: MouseEvent) => {
    if (!(e.target as HTMLElement).className.includes('isHoverable')) {
      return;
    }
    const target = e.target as HTMLElement;
    const {
      left: subMenuLeft,
      right: subMenuRight,
      top: subMenuTop,
      bottom: subMenuBottom
    } = (e.currentTarget as HTMLElement).getBoundingClientRect();
    const { clientY: mousePositionY, clientX: mousePositionX, offsetX } = e;

    if (
      mousePositionX > subMenuLeft &&
      mousePositionX < subMenuRight &&
      mousePositionY > subMenuTop &&
      mousePositionY < subMenuBottom
    ) {
      const sourceRectangle = target?.getBoundingClientRect();
      activeSafeArea?.current &&
        (activeSafeArea.current.style.clipPath = `polygon(${
          ((sourceRectangle.x + offsetX) / window.innerWidth) * 100
        }% 0, 100% 100%, 0 100%)`);
    }
  }, []);

  const untrackSafeTargetArea = useCallback(() => {
    activeSafeArea = null;
    document.body.removeEventListener('mousemove', updateSafeTargetArea);
  }, []);

  const handleMouseOver = useCallback((e: React.MouseEvent, key: string) => {
    show(key);
    setActiveSafeAreaKey(key);
    activeSafeArea = safeAreasRef[key];
    activeSafeArea &&
      document.body.addEventListener('mousemove', updateSafeTargetArea);
  }, []);

  const handleMouseLeave = useCallback((e: React.MouseEvent, key: string) => {
    const { clientY: mousePositionY, clientX: mousePositionX } = e;
    const {
      bottom: subMenuBottom,
      left: subMenuLeft,
      right: subMenuRight
    } = (e.target as HTMLElement).getBoundingClientRect();
    if (
      mousePositionY > Math.trunc(subMenuBottom) ||
      mousePositionX < Math.trunc(subMenuLeft) ||
      mousePositionX > Math.trunc(subMenuRight)
    ) {
      hide(key);
      untrackSafeTargetArea();
    }
  }, []);

  return (
    <div className={classes}>
      <ul
        ref={ULRef}
        className={`flex flex-wrap items-center scroll-smooth mt-1px text-navy`}
      >
        {navLinks &&
          navLinks.map((navLink: TemplateType, idx) => {
            const key =
              navLink.data.url?.data || navLink.data.content?.data?.title;
            safeAreasRef[key] = useRef<HTMLSpanElement>(null);

            return (
              <li
                className={`isHoverable px-1em first:pl-0 text-tiniest uppercase whitespace-nowrap font-bold hover:underline underline-offset-[3px] leading-default tracking-normal h-full flex items-center`}
                key={`${key}-${idx}`}
                onMouseOver={(e) => handleMouseOver(e, key)}
                onMouseLeave={(e) => handleMouseLeave(e, key)}
              >
                {activeSafeAreaKey === key && (
                  <span
                    data-testid="safeArea"
                    className={`isHoverable absolute w-full h-4 mt-5 z-10 left-0`}
                    ref={safeAreasRef[key]}
                  ></span>
                )}
                {navLink.component === 'BasicMenu' && (
                  <SmartLink
                    href={navLink.data.url?.data}
                    classes="basic-menu__link isHoverable"
                  >
                    {navLink.data.title?.data}
                  </SmartLink>
                )}
                {navLink.component === 'BasicMenuRef' && (
                  <BasicMenuRef {...navLink} showCloseMenu />
                )}
                {navLink.component === 'BasicMenuRefRenutriv' && (
                  <BasicMenuRefRenutriv {...navLink} showCloseMenu />
                )}
                {navLink.component === 'BasicMenuRefAerin' && (
                  <BasicMenuRefAerin {...navLink} showCloseMenu />
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
