import cn from 'classnames';
import { useCallback, useRef, useState } from 'react';
import {
  IconClose,
  IconMenu,
  IconSearch,
  LogoEstee
} from '@gemini/brand/el/base-theme';
import { Hamburger } from '@gemini/brand/el/ui/molecules/hamburger';
import { Animate } from '@gemini/shared/services/configuration/utils';
import { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { Button } from '@gemini/shared/ui/atoms/buttons';
import { BrandLogoCustom } from '@gemini/shared/ui/organisms/brand-logo';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';
import { useScreenInfo } from '@gemini/shared/ui/utils/hooks';
import { BRAND_LOGO_DESKTOP } from '@gemini/shared/utils/testing';
import styles from './DesktopHeader.module.scss';
import { NavDesktop } from './NavDesktop';
import { NavMobile } from './NavMobileLazy';

export interface IHeaderTemplate extends TemplateType {
  component: string;
  data: {
    templates: TemplateType[];
  };
}
export interface IDesktopHeaderProps {
  isOpenHamburger: boolean;
  setIsOpenHamburger: React.Dispatch<React.SetStateAction<boolean>>;
  containerHeight: number;
  setShowSubNavTout: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedNavItem: React.Dispatch<React.SetStateAction<any>>;
  navLinks?: any[];
  smallView?: boolean;
  gnavPrimary: IHeaderTemplate;
  utilities?: TemplateType;
  searchData: any;

  triggerSearchOverlay(): void;
}

export const DesktopHeader = ({
  gnavPrimary,
  isOpenHamburger,
  setIsOpenHamburger,
  navLinks = [],
  utilities,
  triggerSearchOverlay
}: IDesktopHeaderProps) => {
  const [showMenuItems, setShowMenuItems] = useState(true);
  const [showMenuLinkHeader, setShowMenuLinkHeader] = useState(false);
  const [showMenuLink, setShowMenuLink] = useState(false);
  const [showMenuTout, setShowMenuTout] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState();
  const [selectedMenuLinkHeader, setSelectedMenuLinkHeader] = useState();
  const [selectedMenuLinkHeaderTitle, setSelectedMenuLinkHeaderTitle] =
    useState();
  const [animate, setAnimate] = useState<Animate | undefined>(undefined);
  const {
    elc_search: { search: searchLabel }
  } = useTranslations();

  const menuRef = useRef<HTMLDivElement>(null);

  const { tabletAndUp } = useScreenInfo();

  const hasWishlist = Boolean(
    utilities?.data?.templates[0].data.enableWishlist.data
  );

  const {
    elc_general: { menu: menu }
  } = useTranslations();

  const toggleHamburger = useCallback(() => {
    setIsOpenHamburger(!isOpenHamburger);
  }, [!isOpenHamburger]);

  return (
    <div
      className={cn(
        'font-akzidenzgrotesk desktop-header',
        styles['desktop-header']
      )}
      ref={menuRef}
    >
      <div
        className={`relative flex items-start justify-between transition-all duration-300 ease-linear max-h-screen h-61px portrait:h-[71px] pt-9px`}
      >
        <div className="flex">
          <div className="lg-up:hidden flex flex-col items-center w-[86px] h-[52px] border-r border-solid border-[#cccccc]">
            <Hamburger
              onClick={toggleHamburger}
              isOpen={isOpenHamburger}
              iconClose={<IconClose width={20} height={20} />}
              iconMenu={<IconMenu width={20} height={20} />}
            />
            <span className="text-[12px] font-akzidenzgrotesk tracking-[.15em] uppercase">
              {menu}
            </span>
          </div>

          <BrandLogoCustom
            className={cn('pl-[19px] pt-[11px] brand-logo', {
              'brand-logo-uk': tabletAndUp && hasWishlist
            })}
            dataTestId={BRAND_LOGO_DESKTOP}
          >
            <LogoEstee width={236} height={31} />
          </BrandLogoCustom>
        </div>
        <div className="flex items-stretch h-52px">
          {utilities && <TemplateRenderer {...utilities} />}
          <div className="flex items-center px-20px min-w-[44px] md:border-silver md:border-x">
            <Button
              onClick={triggerSearchOverlay}
              className="bg-none"
              aria-label={searchLabel}
            >
              <div className="min-w-[24px] search-icon">
                <IconSearch
                  title="Search Products"
                  width={24}
                  height={24}
                  fill={'#040A2B'}
                  color={'#040A2B'}
                />
              </div>
            </Button>
          </div>
        </div>
      </div>
      <NavDesktop
        menuRef={menuRef}
        classes={`hidden lg-up:block pl-19px min-h-[18px] desktop-header-nav ${styles['desktop-header']}`}
        navLinks={navLinks}
      />
      <NavMobile
        {...gnavPrimary}
        classes={`lg-up:hidden`}
        isOpenHamburger={isOpenHamburger}
        setIsOpenHamburger={setIsOpenHamburger}
        showMenuItems={showMenuItems}
        setShowMenuItems={setShowMenuItems}
        showMenuLinkHeader={showMenuLinkHeader}
        setShowMenuLinkHeader={setShowMenuLinkHeader}
        showMenuLink={showMenuLink}
        setShowMenuLink={setShowMenuLink}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        selectedMenuLinkHeader={selectedMenuLinkHeader}
        setSelectedMenuLinkHeader={setSelectedMenuLinkHeader}
        selectedMenuLinkHeaderTitle={selectedMenuLinkHeaderTitle}
        setSelectedMenuLinkHeaderTitle={setSelectedMenuLinkHeaderTitle}
        showMenuTout={showMenuTout}
        setShowMenuTout={setShowMenuTout}
        animate={animate}
        setAnimate={setAnimate}
      />
    </div>
  );
};

export default DesktopHeader;
